
import MyBlog from '@/components/blog/index.vue';
import { addInterestCompany, deleteInterestCompany, getMyInterestCompanyList } from '@/api/interestCompany';
import { getMainViewIds } from '@/api/menu';
import { getBlog } from '@/api/myBlog';
import { getPostList } from '@/api/post';
import { UserModule } from '@/store/user';
import { IBlogList, IPost } from '@/types';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'BlogDetail',
  components: {
    MyBlog,
  },
})
export default class extends Vue {
  mounted() {
    this.init();
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isLogined() {
    return UserModule.token;
  }

  private blog: IBlogList | null = null;

  private interestCompanyList: string[] = [];

  private supportPostList: IPost[] = [];

  private blogVisible = false;

  private supportMenu = {
    boardUid: '',
    menuUid: '',
  };

  private init() {
    getBlog(this.$route.params.uid).then((res) => {
      this.blog = res.data;
    });
    getMyInterestCompanyList().then((res) => {
      this.interestCompanyList = res.data;
    });
    getMainViewIds().then((res) => {
      if (res.data.length > 0) {
        this.supportMenu = {
          ...res.data[0],
        };
        const listQuery = {
          page: 0,
          size: 5,
          userUid: this.$route.params.uid,
          boardUid: res.data[0].boardUid,
        };
        getPostList(listQuery).then((response) => {
          this.supportPostList = response.data.content;
        });
      }
    });
  }

  private isInterestCompany(uid: string) {
    return this.interestCompanyList.indexOf(uid) > -1;
  }

  private handleClickInterest(uid: string, follow: boolean) {
    if (follow) {
      addInterestCompany(uid).then(() => {
        this.interestCompanyList.push(uid);
      });
    } else {
      deleteInterestCompany(uid).then(() => {
        const index = this.interestCompanyList.indexOf(uid);
        if (index > -1) this.interestCompanyList.splice(index, 1);
      });
    }
  }

  private getRouterName() {
    if (this.$route.name === 'SupportDetail') return 'Support';
    if (this.$route.name === 'InvestDetail') return 'Invest';
    return '';
  }

  private handleClickBlog() {
    this.blogVisible = !this.blogVisible;
  }

  private getUrl(url: string) {
    if (url.startsWith('http') || url.startsWith('https')) return url;
    return `http://${url}`;
  }
}
