
import { Vue, Component } from 'vue-property-decorator';
import BlogDetail from '@/components/blog/detail.vue';

@Component({
  name: 'SupportDetail',
  components: {
    BlogDetail,
  },
})
export default class extends Vue {
  private applyActive() {
    alert('정상적으로 접수처리 되었습니다.');
  }
}
